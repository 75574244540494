import React, { useEffect, useContext } from 'react';
import { UserContext } from '../utils/UserContext'; // adjust the path according to your project structure
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { DialogContentText } from '@mui/material';

import {
  GoogleAuthProvider,
  browserPopupRedirectResolver,
  signInWithPopup,
  getRedirectResult,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';
import { auth, db } from '../utils/firebase.js';
import { doc, getDoc, setDoc } from 'firebase/firestore';

function Login() {
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    console.log('Attempting to sign in with Google...');
    console.log('Redirecting to Google for authentication...');
    signInWithPopup(auth, provider, browserPopupRedirectResolver).then(
      (result) => {
        console.log('Successfully signed in with Google');
        const authUser = result.user;
        console.log(`Logged in as: ${authUser.displayName}`);
        const simpleUser = {
          uid: authUser.uid,
          displayName: authUser.displayName,
          email: authUser.email,
          // add more user info here if needed
        };

        setUser(simpleUser);
        navigate('/hub');

        // Firestore operations
        console.log(db);
        const userDocRef = doc(db, 'users', authUser.uid);

        // Check if the user document already exists
        getDoc(userDocRef).then((docSnapshot) => {
          if (!docSnapshot.exists()) {
            // Only set the document if it does not already exist
            setDoc(userDocRef, {
              displayName: authUser.displayName,
              email: authUser.email,
              // add more user info here if needed
            }).catch((error) =>
              console.error(
                'Error storing user in Firestore: ',
                error.code,
                error.message
              )
            );
          }
        });

        // Send token to the server
        authUser.getIdToken(true).then((idToken) => {
          fetch('/api/firebase/sessionLogin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ idToken }),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.status === 'success') {
                console.log('Session cookie set');
              } else {
                console.error('Failed to set session cookie:', data.message);
              }
            });
        });
      }
    );
  };

  const handleSignOut = () => {
    console.log('Attempting to sign out...');
    signOut(auth)
      .then(() => {
        console.log('Successfully signed out');
        setUser(null);
      })
      .catch((error) => {
        console.error('Error during sign out:', error);
        // handle sign out error
      });
  };

  useEffect(() => {
    console.log('Handling redirect...');
    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          console.log('Redirect result:', result);
          const authUser = result.user;
          console.log('authUser in getRedirectResult:', authUser);
          if (authUser) {
            console.log(`Logged in as: ${authUser.email}`);
            const simpleUser = {
              uid: authUser.uid,
              displayName: authUser.displayName,
              email: authUser.email,
              // add more user info here if needed
            };

            setUser(simpleUser);
            navigate('/hub');

            // Add the same Firestore operations and token sending code here as well.
          }
        } else {
          console.log('No redirect result');
        }
      })
      .catch((error) => console.error('Error when handling redirect:', error));

    console.log('Setting up auth state listener...');
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      console.log('authUser in onAuthStateChanged:', authUser);
      if (authUser) {
        console.log('User is logged in');
        navigate('/hub');
      } else {
        console.log('No user is logged in');
        console.log('This is a new version, the deplyment is working');
      }
    });

    return () => {
      console.log('Cleaning up auth state listener');
      unsubscribe();
    };
  }, [navigate, setUser]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '50px',
        alignItems: 'center',
        maxWidth: '80%',
        margin: 'auto',
      }}
    >
      <Typography variant="h4" component="h1" sx={{ marginTop: '20px' }}>
        Storyworld
      </Typography>
      {user ? (
        <Button variant="contained" color="primary" onClick={handleSignOut}>
          Sign Out ({user.email})
        </Button>
      ) : (
        <Button variant="contained" color="primary" onClick={signInWithGoogle}>
          Sign in with Google
        </Button>
      )}

      <Box
        sx={{
          bgcolor: '#111111',
          color: 'white',
          padding: '10px',
          textAlign: 'left',
          borderRadius: '5px',
          maxWidth: '600px',
          wordWrap: 'break-word',
          whiteSpace: 'pre-wrap',
        }}
      >
        <DialogContentText>
          Bonjour and welcome to Storyworld, early beta version 0.3.0 <br />
          <br />
          Storyworld is a narrative management system applied here to a
          text-based role-playing game. In addition to endless adventure, you
          will find bugs and opportunities for improvement. This is normal at
          this stage. We welcome all bug reports, and thank you for any details.
          Opinions, ideas and insight are also welcome, as well as offers for
          involvement of any kind. <br />
          <br />
          Storyworld will be undergoing user testing and market validation this
          fall, with the aim for an initial release by the end of the year.{' '}
          <br />
          <br />
          Our ever-evolving roadmap currently includes the following features:{' '}
          <br />* Deeper world creation
          <br />* Deeper character creation
          <br />* Pre-structured adventures
          <br />* Quest log
          <br />* Share world via URL
          <br />* Saved encounters and relationships
          <br />* Automated chapter resolution
          <br />* Text streaming
          <br />* User interface design
          <br />* Store adventures in vector database for semantic retrieval.{' '}
          <br />
          <br /> May your exploration be fruitful.
          <br />
          <br /> david at storyworldinteractive dot com{' '}
        </DialogContentText>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '50px',
          alignItems: 'center',
          maxWidth: '80%',
          margin: 'auto',
        }}
      ></Box>
    </Box>
  );
}

export default Login;
