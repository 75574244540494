import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#d3d3d3', // light grey
    },
  },
});

console.log('Creating React root element');

const rootElement = document.getElementById('root');

console.log('Rendering React application with ThemeProvider');

createRoot(rootElement).render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>
);

console.log('React application has been rendered');
