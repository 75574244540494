import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavBar';

import axios from 'axios';
import useAuth from '../utils/useAuth';
import { auth, db } from '../utils/firebase.js';
import { doc, getDoc, setDoc } from 'firebase/firestore';

function NewWorldPage() {
  const { user } = useAuth();
  const [keywords, setKeywords] = useState('');
  const [worldDescription, setWorldDescription] = useState('');
  const [worldName, setWorldName] = useState('');
  const [isWorldGenerated, setIsWorldGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const worldBoxRef = useRef(null);

  const [worldType, setWorldType] = useState(
    'a completely new and invented world'
  );
  const [narrativeSystem, setNarrativeSystem] = useState('generated');
  const [narrativeTone, setNarrativeTone] = useState('');

  const handleCreateWorldClick = async (keywords) => {
    setIsLoading(true);
    setWorldName('');
    setWorldDescription('');
    // Save info to local storage
    localStorage.setItem('wType', worldType);
    localStorage.setItem('nmsType', narrativeSystem);
    localStorage.setItem('nTone', narrativeTone);
    // Get world name
    try {
      const wName = await axios.post('/api/openai/generateContent', {
        type: 'wName',
        keywords: keywords,
        wType: worldType,
        nmsType: narrativeSystem,
        nTone: narrativeTone,
        userId: user.uid,
      });
      // Show world name
      setWorldName(wName.data.response);
      // Save world name to local storage
      localStorage.setItem('wName', wName.data.response);
    } catch (error) {
      console.error('Error in handleCreateWorldClick:', error);
      if (error.response) {
        console.error('Error Response:', error.response);
      }
    }

    const wDesc = await axios.post('/api/openai/generateContent', {
      type: 'wDesc',
      keywords: keywords,
      wName: localStorage.getItem('wName'),
      wType: worldType,
      nmsType: narrativeSystem,
      nTone: narrativeTone,
      userId: user.uid,
    });
    setWorldDescription(wDesc.data.response);
    localStorage.setItem('wDesc', wDesc.data.response);

    setIsLoading(false);
  };

  const handleAcceptClick = async () => {
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
    setIsWorldGenerated(false);
    navigate('/character-creation');
    localStorage.setItem('newWorld', true);
    localStorage.setItem('wName', worldName);
    localStorage.setItem('wDesc', worldDescription);
    localStorage.setItem('wOwner', user.uid);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCreateWorldClick(keywords);
  };

  useEffect(() => {
    if ((worldDescription || worldName) && worldBoxRef.current) {
      // Scroll the world box into view when the worldDescription or worldName is set

      setTimeout(() => {
        worldBoxRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  }, [worldDescription, worldName]);

  return (
    <>
      <NavigationBar user={user} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          maxWidth: '80%',
          margin: 'auto',
        }}
      >
        <Typography variant="h4" component="h1" sx={{ marginTop: '20px' }}>
          Create a new world
        </Typography>

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              maxWidth: 800,
            }}
          >
            <FormControl component="fieldset">
              <FormLabel component="legend">World Type</FormLabel>
              <RadioGroup
                aria-label="worldType"
                name="worldType"
                value={worldType}
                onChange={(e) => setWorldType(e.target.value)}
                disabled={isLoading}
              >
                <FormControlLabel
                  value="an existing, real world, past or present"
                  control={<Radio />}
                  label="Existing real world"
                />
                <Typography variant="caption"></Typography>
                <FormControlLabel
                  value="an existing fictional world"
                  control={<Radio />}
                  label="Existing fictional world"
                />
                <Typography variant="caption"></Typography>
                <FormControlLabel
                  value="a variation on an existing world"
                  control={<Radio />}
                  label="Variation on an existing world"
                />
                <Typography variant="caption"></Typography>
                <FormControlLabel
                  value="a completely new and invented world"
                  control={<Radio />}
                  label="A completely new world"
                />
                <Typography variant="caption"></Typography>
              </RadioGroup>
            </FormControl>
          </Box>

          <Box sx={{ height: '30px' }}></Box>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Enter some keywords that describe your world."
                placeholder="For example: 'Feudal Japan, Kamakura period' or 'Solarpunk meets psychedellia' or 'Twilight vampire story'."
                fullWidth
                value={keywords}
                onChange={(e) => setKeywords(e.target.value)}
                disabled={isLoading}
                variant="outlined"
                multiline
                rows={3} // adjust this value to change the height of the text field
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Describe the tone of your narrator. (optional)"
                placeholder="For example: 'Edgar Allan Poe' or 'Dry and witty' or 'Fun for a 6 year old'."
                fullWidth
                value={narrativeTone}
                onChange={(e) => setNarrativeTone(e.target.value)}
                disabled={isLoading}
                variant="outlined"
                multiline
                rows={3} // adjust this value to change the height of the text field
              />
            </Grid>
          </Grid>

          <Box sx={{ height: '10px' }}></Box>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isWorldGenerated || isLoading}
            sx={{ width: '200px', marginTop: '10px' }}
          >
            {isLoading ? 'Thinking...' : 'Create World'}
          </Button>
        </form>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '20px',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {worldName && (
            <TextField
              value={worldName}
              fullWidth
              InputProps={{
                readOnly: true,
              }}
            />
          )}

          {worldDescription && (
            <TextField
              value={worldDescription}
              fullWidth
              multiline
              InputProps={{
                readOnly: true,
              }}
            />
          )}

          {worldName && worldDescription && (
            <div ref={worldBoxRef}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAcceptClick}
                sx={{ width: '200px' }}
              >
                Accept
              </Button>
            </div>
          )}

          <Box sx={{ height: '20px' }} />
        </Box>
      </Box>
    </>
  );
}

export default NewWorldPage;
