// utils/ChatContext.js

import React, { useState, createContext } from 'react';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chatLog, setChatLog] = useState([]);

  return (
    <ChatContext.Provider value={{ chatLog, setChatLog }}>
      {children}
    </ChatContext.Provider>
  );
};
