import React, { useState, useEffect, useContext } from 'react';
import { ChatContext } from '../utils/ChatContext';
import NavigationBar from './NavBar';

import { Box, Button, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { auth, db } from '../utils/firebase.js';
import { doc, getDoc, setDoc } from 'firebase/firestore';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { UserContext } from '../utils/UserContext';
import e from 'cors';

function CharacterCreationPage() {
  const { chatLog, setChatLog } = useContext(ChatContext);

  const [keywords, setKeywords] = useState('');
  const [characterDescription, setCharacterDescription] = useState('');
  const [characterNameInput, setCharacterNameInput] = useState('');
  const [characterName, setCharacterName] = useState('');
  const [isWorldGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setUser(firebaseUser);
      setIsLoadingUser(false);
    });

    if (user && localStorage.getItem('newWorld') === 'true') {
      const fetchContent = async () => {
        const wDescShort = await axios.post('/api/openai/generateContent', {
          type: 'wDescShort',
          nmsType: localStorage.getItem('nmsType'),
          wName: localStorage.getItem('wName'),
          wDesc: localStorage.getItem('wDesc'),
          nTone: localStorage.getItem('nTone'),
          userId: user.uid,
        });
        localStorage.setItem('wDescShort', wDescShort.data.response);
      };
      fetchContent();
    }

    return () => {
      unsubscribe();
    };
  }, [setUser, user]);

  const handleCreateCharacterClick = async () => {
    setIsLoading(true);
    localStorage.setItem('cName', characterNameInput);

    setCharacterName('');
    setCharacterDescription('');
    let cName = localStorage.getItem('cName');
    let wName = localStorage.getItem('wName');
    let wDesc = localStorage.getItem('wDesc');
    let wDescShort = localStorage.getItem('wDescShort');
    let wType = localStorage.getItem('wType');
    let nmsType = localStorage.getItem('nmsType');
    let nTone = localStorage.getItem('nTone');

    try {
      const cDesc = await axios.post('/api/openai/generateContent', {
        type: 'cDesc',
        keywords: keywords,
        wName: wName,
        wDesc: wDesc,
        wDescShort: wDescShort,
        cName: cName, //cName.data.response,
        wType: wType,
        nmsType: nmsType,
        nTone: nTone,
        userId: user.uid,
      });
      setCharacterName(cName);
      setCharacterDescription(cDesc.data.response);
      localStorage.setItem('cDesc', cDesc.data.response);
    } catch (error) {
      console.error('Error in handleCreateCharacterClick:', error);
      if (error.response) {
        console.error('Error Response:', error.response);
      }
    }
    setIsLoading(false);
  };

  const handleAcceptClick = async () => {
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
    localStorage.setItem('chapterId', '');
    localStorage.setItem('chapterNumber', '');
    localStorage.setItem('chapterName', '');
    localStorage.setItem('latestRecap', '');

    setChatLog([]);
    // If the world is new, add it to Firestore
    if (localStorage.getItem('newWorld') === 'true') {
      // Define world object
      const world = {
        name: localStorage.getItem('wName'),
        description: localStorage.getItem('wDesc'),
        shortDescription: localStorage.getItem('wDescShort'),
        id: localStorage.getItem('worldId'),
        username: localStorage.getItem('username'),
        owner: user.uid,
        created: Date.now(),
      };
      // Add world object to Firestore
      try {
        const response = await axios.post('/api/firebase/world', world);
        if (response.status === 200) {
          localStorage.setItem('worldId', response.data.id);
        } else {
          console.error('Error adding world:', response.data);
        }
      } catch (error) {
        console.error('Error adding world:', error);
      }
    }
    // Define character object
    const character = {
      name: characterName,
      description: characterDescription,
      world: localStorage.getItem('worldId'),
      worldId: localStorage.getItem('worldId'), // Add this line
      owner: user.uid,
      created: Date.now(),
    };
    // Add character object to Firestore
    try {
      const response = await axios.post('/api/firebase/character', character);
      if (response.status === 200) {
        localStorage.setItem('characterId', response.data.id);
        navigate('/story');
      } else {
        console.error('Error adding character:', response.data);
      }
    } catch (error) {
      console.error('Error adding character:', error);
    }
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCreateCharacterClick(characterNameInput, keywords);
  };
  if (isLoadingUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <NavigationBar user={user} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
          margin: 'auto',
        }}
      >
        <Typography variant="h4" component="h4" sx={{ marginTop: '20px' }}>
          Create a new character
        </Typography>
        <Box sx={{ height: '5px' }} />

        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              width: {
                xs: '90%', // on extra small (xs) and larger screens, make the width 90% of the screen size
                sm: '75%', // on small (sm) and larger screens, make the width 75% of the screen size
                md: '600px', // on medium (md) and larger screens, make the width 600px
              },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              placeholder="Name your character."
              fullWidth
              value={characterNameInput}
              onChange={(e) => setCharacterNameInput(e.target.value)}
              disabled={isLoading}
            />
          </Box>
          <Box sx={{ height: '20px' }} />

          <Box
            sx={{
              width: {
                xs: '90%', // on extra small (xs) and larger screens, make the width 90% of the screen size
                sm: '75%', // on small (sm) and larger screens, make the width 75% of the screen size
                md: '600px', // on medium (md) and larger screens, make the width 600px
              },
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              placeholder="Describe your character."
              fullWidth
              multiline
              rows={3}
              onChange={(e) => setKeywords(e.target.value)}
              disabled={isLoading}
            />
          </Box>
          <Box sx={{ height: '20px' }} />

          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isWorldGenerated || isLoading}
            sx={{ width: '200px', marginTop: '10px' }}
          >
            {isLoading ? 'Thinking...' : 'Create Character'}
          </Button>
        </form>

        {characterName && characterDescription && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{ width: '600px', display: 'flex', justifyContent: 'center' }}
            >
              <TextField
                value={characterName}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Box
              sx={{ width: '600px', display: 'flex', justifyContent: 'center' }}
            >
              <TextField
                value={characterDescription}
                fullWidth
                multiline
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleAcceptClick}
              sx={{ width: '200px' }}
            >
              Accept
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
}
export default CharacterCreationPage;
