import axios from 'axios';

class MemoryManager {
  constructor(user) {
    this.user = user;
    this.shortMem = [];
    this.midMem = '';
    this.longMem = [];
    this.npcShortMem = [];
    this.npcMidMem = '';
    this.shortMemSize = 6;
    this.midMemSize = 8;
    this.shortMemCounter = 0;
    this.npcShortMNemCounter = 0;
  }

  addToShortMem(entry) {
    // add entry to shortMem
    this.shortMem.push(entry);
    this.shortMemCounter++;

    // keep only last 8 entries
    if (this.shortMem.length > this.shortMemSize) {
      this.shortMem.shift();
    }

    // If we've added X new entries since the last summary, update midMem
    if (this.shortMemCounter === 6) {
      this.updateMidMem();
      this.shortMemCounter = 0;
    }
  }

  async updateMidMem() {
    const res = await axios.post('/api/openai/memoryManager', {
      type: 'summarizeMidMem',
      cName: localStorage.getItem('cName'),
      npcName: localStorage.getItem('npcName'),
      text: this.shortMem
        .map((entry) => `${entry.role}: ${entry.content}\n\n`)
        .join(' '),
      userId: this.user.uid,
    });

    // append the summary to midMem
    this.midMem += ' ' + res.data.response;
  }

  async updateLongMem(chapterNumber, chapterName) {
    const res = await axios.post('/api/openai/memoryManager', {
      type: 'summarizeLongMem',
      cName: localStorage.getItem('cName'),
      text:
        this.midMem +
        ' ' +
        this.shortMem
          .map((entry) => `${entry.role}: ${entry.content}\n\n`)
          .join(' '),
      userId: this.user.uid,
    });
    // add the chapter summary to longMem
    this.longMem.push({
      chapterNumber: chapterNumber,
      chapterName: chapterName,
      chapterRecap: res.data.response,
    });

    // add tne chapter summary to localStorage
    localStorage.setItem('latestRecap', res.data.response);

    // clear shortMem
    this.shortMem = [];

    // clear midMem
    this.midMem = '';
  }

  addToNpcShortMem(entry) {
    // add entry to npcShortMem
    this.npcShortMem.push(entry);
    this.npcShortMemCounter++;

    // keep only last 8 entries
    if (this.npcShortMem.length > this.shortMemSize) {
      this.npcShortMem.shift();
    }

    // If we've added X new entries since the last summary, update npcMidMem
    if (this.npcShortMemCounter === this.midMemSize) {
      this.updateNpcMidMem();
      this.npcShortMemCounter = 0;
    }
  }

  async updateNpcMidMem() {
    const res = await axios.post('/api/openai/memoryManager', {
      type: 'summarizeNpcMidMem',
      text: this.npcShortMem
        .map((entry) => `${entry.role}: ${entry.content}\n\n`)
        .join(' '),
      userId: this.user.uid,
      cName: localStorage.getItem('cName'),
      npcName: localStorage.getItem('npcName'),
    });

    // append the summary to midMem
    this.midNpcMem += ' ' + res.data.response;
  }

  /*
async updateNpcLongMem(chapterNumber, chapterName) {   
    const res = await axios.post('/api/openai/memoryManager', {
        type: 'summarizeNpcLongMem',
        text: this.midNpcMem + ' ' + this.shortNpcMem.map((entry) => entry.content).join(' '),
        userId: this.user.uid,
        cName: localStorage.getItem('cName'),
        npcName: localStorage.getItem('npcName'),
    });

    // add the conversation summary to midMem
    this.longMem.push({
        chapterNumber: chapterNumber,
        chapterName: chapterName,
        chapterRecap: res.data.response,
      });
  
      // add tne chapter summary to localStorage
      localStorage.setItem('latestRecap', res.data.response);
  
      // clear shortMem
      this.shortMem = [];
  
      // clear midMem
      this.midMem = '';
}
*/

  getShortMem() {
    return this.shortMem;
  }
  getMidMem() {
    return this.midMem;
  }
  getLongMem() {
    return this.longMem;
  }
  getNpcShortMem() {
    return this.npcShortMem;
  }
  getNpcMidMem() {
    return this.npcMidMem;
  }
}

export default MemoryManager;
