import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyD3p9X5W_9R0wRbP-SZA1ygu3U8ZAIE6RI',
  authDomain: 'idyllic-folio-383412.firebaseapp.com',
  projectId: 'idyllic-folio-383412',
  storageBucket: 'idyllic-folio-383412.appspot.com',
  messagingSenderId: '736556673379',
  appId: '1:736556673379:web:3fb71b0110ecda68d58b56',
  measurementId: 'G-GYM23VHLJD',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { app, auth, db };
