import React, { useState, useContext } from 'react';
import { UserContext } from '../utils/UserContext';
import { textFieldContext } from '../utils/tFieldContext';

import { TextField } from '@mui/material';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/system';
import { auth } from '../utils/firebase.js';
import { useNavigate } from 'react-router-dom';

const StyledToolbar = styled(Toolbar)({
  padding: '10 !important',
  minHeight: '0 !important',
  height: '40px !important',
});

const StyledAppBar = styled(AppBar)({
  backgroundColor: '#030303',
});

export default function NavigationBar() {
  const { user, setUser } = useContext(UserContext);
  const { textField } = React.useContext(textFieldContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleSignOut = () => {
    auth
      .signOut()
      .then(() => {
        console.log('User signed out');
        setUser(null);
        navigate(`/`);
      })
      .catch((error) => {
        console.error('Sign out error', error);
      });
  };

  const handleAbout = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <StyledAppBar position="static">
      <StyledToolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="inherit"
          variant="h4"
          component="div"
          onClick={handleAbout}
          sx={{ textTransform: 'none', fontSize: '16px' }} // For MUI v5+
        >
          Storyworld
        </Button>
        <Typography variant="h6" component="h2">
          {textField}
        </Typography>
        {user ? (
          <Button color="inherit" onClick={handleSignOut}>
            Logout
          </Button>
        ) : (
          <Typography variant="subtitle1" color="inherit">
            You are not logged in
          </Typography>
        )}
      </StyledToolbar>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>About Storyworld</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bonjour and welcome to Storyworld, early beta version 0.3.0 <br />
            <br />
            Storyworld is a narrative management system applied here to a
            text-based role-playing game. In addition to endless adventure, you
            will find bugs and opportunities for improvement. This is normal at
            this stage. We welcome all bug reports, and thank you for any
            details. Opinions, ideas and insight are also welcome, as well as
            offers for involvement of any kind. <br />
            <br />
            Storyworld will be undergoing user testing and market validation
            this fall, with the aim for an initial release by the end of the
            year. <br />
            <br />
            Our ever-evolving roadmap currently includes the following features:{' '}
            <br />* Deeper world creation
            <br />* Deeper character creation
            <br />* Pre-structured adventures
            <br />* Quest log
            <br />* Share world via URL
            <br />* Saved encounters and relationships
            <br />* Automated chapter resolution
            <br />* Text streaming
            <br />* User interface design
            <br />* Store adventures in vector database for semantic retrieval.{' '}
            <br />
            <br /> May your exploration be fruitful.
            <br />
            <br /> david at storyworldinteractive dot com{' '}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </StyledAppBar>
  );
}
