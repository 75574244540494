import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { textFieldContext } from './utils/tFieldContext';

import './App.css';

import Hub from './components/Hub.js';
import NewWorld from './components/NewWorld.js';
import CharacterCreation from './components/CharacterCreation.js';
import Story from './components/Story.js';
import Login from './components/Login.js';
import { UserProvider } from './utils/UserContext';
import { ChatProvider } from './utils/ChatContext';

function App() {
  const [textField, setTextField] = useState('');

  // Log when App function is called
  console.log('App function body called');

  // Log whenever textField state changes
  useEffect(() => {
    console.log('TextField state changed, current value:', textField);
  }, [textField]);

  return (
    <div className="App">
      <textFieldContext.Provider value={{ textField, setTextField }}>
        <UserProvider>
          <ChatProvider>
            <Router>
              <Routes>
                <Route path="/" element={<Login />} />
                {console.log('Rendering Login component')}
                <Route path="/hub" element={<Hub />} />
                {console.log('Rendering Hub component')}
                <Route path="/new-world" element={<NewWorld />} />
                {console.log('Rendering NewWorld component')}
                <Route
                  path="/character-creation"
                  element={<CharacterCreation />}
                />
                {console.log('Rendering CharacterCreation component')}
                <Route path="/story" element={<Story />} />
                {console.log('Rendering Story component')}
              </Routes>
            </Router>
          </ChatProvider>
        </UserProvider>
      </textFieldContext.Provider>
    </div>
  );
}

export default App;
