import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NavigationBar from './NavBar';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  CardActionArea,
  Tooltip,
  Box,
} from '@mui/material';
import useAuth from '../utils/useAuth.js';
import { auth, db } from '../utils/firebase.js';
import {
  doc,
  getDoc,
  setDoc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

function HubPage() {
  const { user } = useAuth();
  const [userWorlds, setUserWorlds] = useState([]);
  const [otherWorlds, setOtherWorlds] = useState([]);
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedWorld, setSelectedWorld] = useState(null);
  const [username, setUsername] = useState(null);
  const [usernameDialogOpen, setUsernameDialogOpen] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('storyStarted', 'true');

    const fetchWorlds = async () => {
      try {
        // Fetch all characters for the user.
        const resCharacters = await axios.get(
          `/api/firebase/characters/${user.uid}`
        );
        const characters = resCharacters.data;

        // Get unique world IDs
        const userWorldIds = [...new Set(characters.map((c) => c.worldId))];

        // Fetch all worlds.
        const resAllWorlds = await axios.get('/api/firebase/all-worlds');
        const allWorlds = resAllWorlds.data;

        // Split the worlds into "visited" and "unexplored" based on the character data.
        const userWorlds = allWorlds.filter((w) => userWorldIds.includes(w.id));
        const otherWorlds = allWorlds.filter(
          (w) => !userWorldIds.includes(w.id)
        );

        const userDoc = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userDoc);

        if (userSnap.exists() && userSnap.data().username) {
          // If the user's data contains a username, set it in the state
          setUsername(userSnap.data().username);
          localStorage.setItem('username', userSnap.data().username);
        } else {
          // If the user's data does not contain a username, show the username dialog
          setUsernameDialogOpen(true);
        }

        // Update state.
        setUserWorlds(userWorlds);
        setOtherWorlds(otherWorlds);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (user) {
      fetchWorlds();
    }
  }, [user]);

  const handleRecentClick = async (world) => {
    // set world to local storage
    localStorage.setItem('wName', world.name);
    localStorage.setItem('wDesc', world.description);
    localStorage.setItem('wDescShort', world.shortDescription);
    localStorage.setItem('wOwner', world.owner);
    localStorage.setItem('worldId', world.id);
    localStorage.setItem('newWorld', 'false');

    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, 'characters'),
          where('owner', '==', user.uid),
          where('worldId', '==', world.id)
        )
      );

      // Get the docs from the querySnapshot first.
      const docs = querySnapshot.docs;

      for (const doc of docs) {
        const characterData = doc.data();

        // set character to local storage
        localStorage.setItem('cName', characterData.name);
        localStorage.setItem('cDesc', characterData.description);
        localStorage.setItem('cOwner', characterData.owner);
        localStorage.setItem('characterId', doc.id);

        // fetch chapters for this character
        const chapterSnapshot = await getDocs(collection(doc.ref, 'chapters'));

        if (!chapterSnapshot.empty) {
          const chapters = chapterSnapshot.docs.map((doc) => doc.data());
          const latestChapter = chapters[chapters.length - 1];
          localStorage.setItem('chapterId', latestChapter.id);
          localStorage.setItem('chapterNumber', latestChapter.number);
          localStorage.setItem('chapterName', latestChapter.name);
          localStorage.setItem('latestRecap', latestChapter.recap);
        } else {
          localStorage.setItem('latestRecap', '');
        }
      }
      navigate(`/story`);
    } catch (error) {
      console.error('Error getting character:', error);
    }
  };

  const handleUnexploredClick = (world) => {
    localStorage.setItem('newWorld', true);
    // set world to local storage
    localStorage.setItem('wName', world.name);
    localStorage.setItem('wDesc', world.description);
    localStorage.setItem('wDescShort', world.shortDescription);
    localStorage.setItem('wOwner', world.owner);
    localStorage.setItem('latestRecap', '');
    navigate(`/character-creation`);
  };

  // Function to handle the submission of the username
  const handleUsernameSubmit = async () => {
    // Save the entered username in the database
    const userDoc = doc(db, 'users', user.uid);
    await setDoc(userDoc, { username }, { merge: true });
    localStorage.setItem('username', username);
    // Close the username dialog
    setUsernameDialogOpen(false);
  };

  const handleCardClick = (world) => {
    // Determine if the world belongs to the user
    const isUserWorld = userWorlds.some(
      (userWorld) => userWorld.id === world.id
    );
    setSelectedWorld({ ...world, isUserWorld });
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setSelectedWorld(null);
  };

  const handleGoToWorld = () => {
    selectedWorld.isUserWorld
      ? handleRecentClick(selectedWorld)
      : handleUnexploredClick(selectedWorld);
    handleClose();
  };

  return (
    <>
      <NavigationBar user={user} />
      <Dialog
        open={usernameDialogOpen}
        onClose={() => setUsernameDialogOpen(false)}
      >
        <DialogTitle>Enter a username</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter a username to continue.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            fullWidth
            onChange={(e) => setUsername(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUsernameSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          m: 2,
          display: 'grid',
          gridTemplateColumns: ['1fr', null, '1fr 1fr'],
          gap: '2em',
        }}
      >
        <Box
          sx={{
            gridColumn: '1 / -1',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ marginTop: '20px', color: '#FFFFFF' }}
          >
            Worlds
          </Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Button
              variant="contained"
              onClick={() => navigate('/new-world')}
              sx={{ height: '100%' }}
            >
              Create a new world
            </Button>
          </Box>
        </Box>
        <Box sx={{ gridColumn: [1, null, 1] }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              backgroundColor: '#EEEEEE',
              padding: '0px',
              borderRadius: '10px',
              color: '#4a4a4a',
              fontWeight: 'normal',
              marginBottom: '10px',
            }}
          >
            Recent
          </Typography>

          {userWorlds.length > 0 ? (
            <Grid container spacing={0.5}>
              {userWorlds.map((world, index) =>
                world.name ? (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ marginBottom: '1px' }}>
                      <CardActionArea onClick={() => handleCardClick(world)}>
                        <CardContent
                          sx={{
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between', // Add this line
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              marginRight: '10px',
                              marginLeft: '10px',
                            }}
                          >
                            {world.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: '10px',
                              marginLeft: '10px',
                            }}
                          >
                            {world.username || '--'}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                ) : null
              )}
            </Grid>
          ) : (
            <Card sx={{ backgroundColor: '#111111' }}>
              <CardContent>
                <Typography variant="h7" component="div" color="#888888">
                  You have not created or visited any worlds.
                </Typography>
              </CardContent>
            </Card>
          )}
        </Box>

        <Box sx={{ gridColumn: [1, null, 2] }}>
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              backgroundColor: '#EEEEEE',
              padding: '0px',
              borderRadius: '10px',
              color: '#4a4a4a',
              fontWeight: 'normal',
              marginBottom: '10px',
            }}
          >
            Unexplored
          </Typography>

          <Grid container spacing={0.5}>
            {otherWorlds.map(
              (world, index) =>
                world.name && (
                  <Grid item xs={12} key={index}>
                    <Card sx={{ marginBottom: '1px' }}>
                      <CardActionArea onClick={() => handleCardClick(world)}>
                        <CardContent
                          sx={{
                            padding: '0px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between', // Add this line
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ marginRight: '10px', marginLeft: '10px' }}
                          >
                            {world.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              marginRight: '10px',
                              marginLeft: '10px',
                            }}
                          >
                            {world.username || '--'}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )
            )}
          </Grid>
        </Box>
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>{selectedWorld?.name}</DialogTitle>
          <DialogContent>
            <DialogContentText>{selectedWorld?.description}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Back
            </Button>
            <Button onClick={handleGoToWorld} color="primary">
              {selectedWorld?.isUserWorld
                ? 'Go to World'
                : 'Create a character in this world'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
}

export default HubPage;
