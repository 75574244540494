import React, { useState, useEffect, useRef, useContext, memo } from 'react'; // Add useContext
import { ChatContext } from '../utils/ChatContext';
import NavigationBar from './NavBar';

import { textFieldContext } from '../utils/tFieldContext';
import { useMediaQuery } from '@mui/material';

import MemoryManager from './MemoryManager';
import {
  Button,
  Box,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { auth, db } from '../utils/firebase.js';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';

import { UserContext } from '../utils/UserContext'; // Adjust path according to your project structure
import '../App.css';
import '../index.css';

function StoryPage() {
  const [uiChatLog, setUiChatLog] = useState([]);
  const [open, setOpen] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [chatInput, setChatInput] = useState('');
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [chatMode, setchatMode] = useState('story');
  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);
  const [currentTitle, setCurrentTitle] = useState('');
  const wName = localStorage.getItem('wName');
  const inputRef = useRef(null);
  const { setTextField } = useContext(textFieldContext);
  const [exitVisible, setExitVisible] = useState(false);
  const [storyInitialized, setStoryInitialized] = useState(false);
  const [placeholderText, setPlaceholderText] = useState(
    'Explore ' + wName + '...'
  );
  const memoryManagerRef = useRef(new MemoryManager(user));
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToBottom = () => {
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 60);
  };

  const isClickableNPC = (element) =>
    element.classList.contains('clickable-character');

  const getCommonPayload = (user) => ({
    wName: localStorage.getItem('wName'),
    wDesc: localStorage.getItem('wDesc'),
    wDescShort: localStorage.getItem('wDescShort'),
    cName: localStorage.getItem('cName'),
    cDesc: localStorage.getItem('cDesc'),
    wType: localStorage.getItem('wType'),
    nmsType: localStorage.getItem('nmsType'),
    nTone: localStorage.getItem('nTone'),
    bookOutline: localStorage.getItem('bookOutline'),
    chapterNumber: localStorage.getItem('chapterNumber'),
    //currentChapterSeed: localStorage.getItem('currentChapterSeed'),
    npcName: localStorage.getItem('npcName'),
    npcDesc: localStorage.getItem('npcDesc'),
    npcContext: localStorage.getItem('npcContext'),
    chapterTitle: localStorage.getItem('chapterTitle'),
    userId: user ? user.uid : '',
    shortMem: memoryManagerRef.current.getShortMem(),
    midMem: memoryManagerRef.current.getMidMem(),
    longMem: memoryManagerRef.current.getLongMem(),
    npcShortMem: memoryManagerRef.current.getNpcShortMem(),
    npcMidMem: memoryManagerRef.current.getNpcMidMem(),

    lastChapterRecap: localStorage.getItem('latestRecap'),
  });

  // On page load ///////////////////////////////////////////
  useEffect(() => {
    const storyStarted = sessionStorage.getItem('storyStarted');

    if (!storyStarted || storyStarted === 'false') {
      sessionStorage.setItem('storyStarted', 'true');
      navigate('/hub');
    } else {
      sessionStorage.setItem('storyStarted', 'false');

      const initializeStory = async (user) => {
        if (user) {
          setIsLoading(true);
          memoryManagerRef.current = new MemoryManager(user);
          console.log('Initializing story with user: ', user.uid);
          // if a recap exists
          if (localStorage.getItem('latestRecap') !== '') {
            await setNextChapter();
          } else {
            await setFirstChapter();
          }
          setIsPageLoading(false); // Reset the Page loading flag
          setIsLoading(false);
          scrollToBottom();
        }
      };

      const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
        setUser(firebaseUser);
        setIsLoading(false);
        if (firebaseUser && !storyInitialized) {
          initializeStory(firebaseUser);
          setStoryInitialized(true);
        }
      });

      // Cleanup the subscription on unmount
      return () => {
        unsubscribe();
      };
    }
  }, []);

  // Set first chapter function ///////////////////////////////////////////
  const setFirstChapter = async () => {
    try {
      // Put a loading message
      const loading = {
        role: 'system',
        content: 'Seeding the first chapter...',
      };
      setUiChatLog((prevChatLog) => [...prevChatLog, loading]);
      // Get first chapter title
      const res = await axios.post('/api/openai/generateContent', {
        type: 'firstChapterTitle',
        ...getCommonPayload(user),
      });
      // Store and show the title
      localStorage.setItem('chapterNumber', 1);
      localStorage.setItem('chapterTitle', res.data.response);
      setCurrentTitle('Chapter 1: ' + localStorage.getItem('chapterTitle'));
      // Store the chapter
    } catch (error) {
      console.error('Error fetching first chapter:', error);
    }

    // Set the first scene
    try {
      // Put a loading message
      const loading = { role: 'system', content: 'Setting the scene...' };
      setUiChatLog((prevChatLog) => [...prevChatLog, loading]);
      // Get first scene
      console.log('get first scene');
      const res = await axios.post('/api/openai/generateContent', {
        type: 'firstScene',
        ...getCommonPayload(user),
      });
      // Show scene
      let scene;
      scene = {
        role: 'assistant',
        content: res.data.response.replace(
          /\[([^\]]+)\]/g,
          "<span class='clickable-character' >$1</span>"
        ),
      };
      setUiChatLog((prevChatLog) => [...prevChatLog, scene]);
      memoryManagerRef.current.addToShortMem(scene);
    } catch (error) {
      console.error('Error fetching scene:', error);
    }
  };

  // Set next chapter function ///////////////////////////////////////////
  const setNextChapter = async () => {
    setIsLoading(true); // Set the loading flag
    try {
      // Show recap
      var loadingsummary = {
        role: 'system',
        content:
          'Summary of chapter ' +
          localStorage.getItem('chapterNumber') +
          ': ' +
          localStorage.getItem('latestRecap'),
      };
      setUiChatLog([loadingsummary]);

      // Increment chapter number
      let chapterNumber = parseInt(localStorage.getItem('chapterNumber'), 10);
      chapterNumber++;
      localStorage.setItem('chapterNumber', chapterNumber);

      // Put a loading message
      var loading = {
        role: 'system',
        content: 'Seeding chapter ' + chapterNumber + '...',
      };
      setUiChatLog((prevChatLog) => [...prevChatLog, loading]);

      // Get next chapter title
      const res = await axios.post('/api/openai/generateContent', {
        type: 'nextChapterTitle',
        ...getCommonPayload(user),
      });
      // Store and show the title
      localStorage.setItem('chapterTitle', res.data.response);
      setCurrentTitle(
        'Chapter ' + chapterNumber + ': ' + localStorage.getItem('chapterTitle')
      );
    } catch (error) {
      console.error('Error fetching next chapter:', error);
    }

    // Set the next scene
    try {
      // Put a loading message
      const loading = { role: 'system', content: 'Setting the scene...' };
      setUiChatLog((prevChatLog) => [...prevChatLog, loading]);
      // Get next scene
      const res = await axios.post('/api/openai/generateContent', {
        type: 'nextScene',
        ...getCommonPayload(user),
      });
      // Show scene
      const scene = {
        role: 'assistant',
        content: res.data.response.replace(
          /\[([^\]]+)\]/g,
          "<span class='clickable-character' >$1</span>"
        ),
      };
      setUiChatLog((prevChatLog) => [...prevChatLog, scene]);
      memoryManagerRef.current.shortMem = [];
      memoryManagerRef.current.midMem = '';
      memoryManagerRef.current.addToShortMem(scene);
      console.log('shortMem', memoryManagerRef.current.shortMem);
    } catch (error) {
      console.error('Error fetching scene:', error);
    } finally {
      setIsLoading(false); // Set the loading flag
    }
  };

  const handleClickNextChapter = async () => {
    if (chatMode === 'NPC') {
      handleExitNPC();
    }
    // Show chapter complete message
    var loading = {
      role: 'system',
      content:
        'Chapter ' +
        localStorage.getItem('chapterNumber') +
        ' complete, saving chapter...',
    };
    setUiChatLog((prevChatLog) => [...prevChatLog, loading]);
    setIsLoading(true); // Set the loading flag

    // Make chapterRecap & set in local storage
    await memoryManagerRef.current.updateMidMem();
    memoryManagerRef.current.shortMem = [];
    await memoryManagerRef.current.updateLongMem(
      localStorage.getItem('chapterNumber'),
      localStorage.getItem('currentChapterTitle')
    );

    // Define the last chapter object
    const chapter = {
      number: localStorage.getItem('chapterNumber'),
      title: localStorage.getItem('chapterTitle'),
      recap: localStorage.getItem('latestRecap'),
      characterId: localStorage.getItem('characterId'),
      created: Date.now(),
    };

    // Store chapter object to firebase
    try {
      const response = await axios.post('/api/firebase/chapter', chapter);
      if (response.status === 200) {
        console.log('Chapter added successfully:', response.data);
      } else {
        console.error('Error adding chapter:', response.data);
      }
    } catch (error) {
      console.error('Error adding chapter:', error);
    }

    setNextChapter();
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleSendMessage = async (event) => {
    if (event) {
      event.preventDefault();
    }

    // Update chat log with user message
    const newEntry = { role: 'user', content: chatInput };
    setChatInput('');
    setIsLoading(true);
    setUiChatLog((prevChatLog) => [...prevChatLog, newEntry]);
  };

  useEffect(() => {
    // Check that the last message was from the user
    const lastMessage = uiChatLog[uiChatLog.length - 1];
    if (lastMessage && lastMessage.role === 'user') {
      if (chatMode === 'story') {
        memoryManagerRef.current.addToShortMem(lastMessage);
        talktoNarrator().then(() => {
          setIsLoading(false);
        });
      } else if (chatMode === 'NPC') {
        memoryManagerRef.current.addToNpcShortMem(lastMessage);
        talkToNPC().then(() => {
          setIsLoading(false);
        });
      }
    }
  }, [uiChatLog]);

  const handleClickNPC = async (event) => {
    if (isClickableNPC(event.target)) {
      setIsLoading(true);

      const npcName = event.target.innerText;
      localStorage.setItem('npcName', npcName);
      setchatMode('NPC');

      const newUiChatLog = {
        role: 'system',
        content: 'Preparing conversation with ' + npcName + '...',
      };
      setUiChatLog((prevChatLog) => [...prevChatLog, newUiChatLog]);

      setPlaceholderText(`Speak with ${npcName}...`);
      // This was here twice
      // memoryManagerRef.current.updateMidMem();

      Promise.all([createNPC('createNPC'), createNPC('createNPCcontext')]).then(
        () => {
          setIsLoading(false);
          setExitVisible(true);
          // Give npcMidMem the midMem
          memoryManagerRef.current.updateMidMem();
          memoryManagerRef.current.npcMidMem = memoryManagerRef.current.midMem;
          // Give npcShortMem a 'hello' seed
          var seed = {
            role: 'user',
            content: 'Hello ' + npcName,
          };
          memoryManagerRef.current.npcShortMem = [seed];
          talkToNPC(); // REMOVE TO WAIT FOR USER INPUT
        }
      );
    }
  };

  const handleExitNPC = async (event) => {
    if (event) {
      event.preventDefault();
    }
    //    setChatInput(exitMessage);
    setchatMode('story');
    setExitVisible(false);
    setIsLoading(true);
    setUiChatLog((prevChatLog) => [
      ...prevChatLog,
      { role: 'system', content: 'Exiting conversation...' },
    ]);

    setPlaceholderText(`Explore ${wName}...`);

    // Ensure a recap of the conversation
    await memoryManagerRef.current.updateNpcMidMem();
    // Add 'cname had a conversation with characterName' to midMem
    const cName = localStorage.getItem('cName');
    const npcName = localStorage.getItem('npcName');

    // Add recap to midMem
    memoryManagerRef.current.midMem = memoryManagerRef.current.npcMidMem;
    memoryManagerRef.current.midMem +=
      ' The conversation between ' + cName + ' and ' + npcName + ' ended. ';
    // Reset memory
    memoryManagerRef.current.shortMem = [];
    memoryManagerRef.current.npcShortMem = [];
    memoryManagerRef.current.npcMidMem = '';
    setChatInput('');

    // Seed the return
    var seed = {
      role: 'user',
      content:
        'I exit the conversation with ' + npcName + ' and I look around me.',
    };
    memoryManagerRef.current.shortMem = [seed];

    await talktoNarrator().then(() => {
      setIsLoading(false);
    });
  };

  // Create NPC function ///////////////////////////////////////////
  const createNPC = async (type) => {
    try {
      // Get NPC
      const res = await axios.post('/api/openai/NPCs', {
        ...getCommonPayload(user),
        type: type,
      });
      // Store the NPC description
      if (type === 'createNPC') {
        localStorage.setItem('npcDesc', res.data.response);
      } else if (type === 'createNPCcontext') {
        localStorage.setItem('npcContext', res.data.response);
        console.log('THIS IS npcContext: ', res.data.response);
      }
      // return aiChatLog; // do i need to return this?
    } catch (error) {
      console.error('Error fetching book:', error);
    }
  };

  const talktoNarrator = async () => {
    try {
      // Get response from OpenAI
      const res = await axios.post('/api/openai/generateContent', {
        type: 'story',
        ...getCommonPayload(user),
        chatInput: chatInput,
      });

      // Log the response for AI chat log
      let newEntry;
      newEntry = {
        role: 'assistant',
        content: res.data.response,
      };
      memoryManagerRef.current.addToShortMem(newEntry);

      // Show response in chat
      let newUiChatLog;
      newUiChatLog = {
        role: 'assistant',
        content: res.data.response.replace(
          /\[([^\]]+)\]/g,
          "<span class='clickable-character' >$1</span>"
        ),
      };

      setUiChatLog((prevChatLog) => {
        // Remove previous span tags from uiChatLog
        const updatedUiChatLog = prevChatLog.map((entry) => {
          if (entry.role === 'assistant' && entry.content) {
            return {
              ...entry,
              content: entry.content.replace(
                /<span class='clickable-character' >(.+?)<\/span>/g,
                '$1'
              ),
            };
          }
          return entry;
        });

        return [...updatedUiChatLog, newUiChatLog];
      });
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setTimeout(() => inputRef.current.focus(), 50);
    }
  };

  const talkToNPC = async () => {
    try {
      // Get response from OpenAI
      const res = await axios.post('/api/openai/generateContent', {
        type: 'NPC',
        ...getCommonPayload(user),
        chatInput: chatInput,
      });
      // Show response
      let newEntry;
      newEntry = {
        role: 'assistant',
        content: res.data.response,
      };
      memoryManagerRef.current.addToNpcShortMem(newEntry);
      setChatInput('');

      newEntry = {
        role: 'npc',
        content: res.data.response,
      };
      setUiChatLog((prevChatLog) => [...prevChatLog, newEntry]);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
      setExitVisible(true);
      setTimeout(() => inputRef.current.focus(), 50);
    }
  };

  const talktoLoading = async (event) => {};
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    scrollToBottom();
  }, [uiChatLog]);

  const isMobile = useMediaQuery('(max-width:600px)');

  React.useEffect(() => {}, []);

  useEffect(() => {
    const chatContainerEl = chatContainerRef.current;

    if (chatContainerEl) {
      chatContainerEl.addEventListener('click', handleClickNPC);
      console.log('Event listener attached');

      return () => {
        chatContainerEl.removeEventListener('click', handleClickNPC);
      };
    }
  }, [chatContainerRef.current]);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    setTextField(wName);
    return () => setTextField(''); // clear the text field when the component unmounts
  }, [wName, setTextField]);

  useEffect(() => {
    //   setMemoryManager(new MemoryManager(user));

    if (user) {
      setIsPageLoading(false);
    }
  }, [user]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: isMobile ? '0px' : '0px', // 80px assumed as height of navbar
          alignItems: 'stretch',
          width: '100%',

          maxWidth: '120%',
          //height: '10vh',
          maxHeight: '100vh', // ensure it's not taller than the viewport height
          margin: 'auto',
          boxSizing: 'border-box',
          overflow: 'hidden', // prevent scrolling on mobile
        }}
      >
        <NavigationBar
          user={user || {}}
          style={{ position: 'sticky', top: '0', width: '100%', zIndex: 100 }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            justifyContent: 'center',
            paddingTop: isMobile ? '0px' : '0px', // 80px assumed as height of navbar
            paddingBottom: '0px',
            paddingLeft: '20px',
            paddingRight: '20px',
            alignItems: 'stretch',
            flex: 1, // grow to take up all available space
            overflowY: 'auto', // scroll if content overflows
            margin: 'auto',
            width: '100%',

            boxSizing: 'border-box',
          }}
        >
          <Box
            display="flex"
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            flex="1"
            sx={{ marginTop: '10px', flex: 1 }}
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              flex="1"
              flexWrap="wrap" // New property to allow the items to wrap onto a new line if needed
              width="100%"
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                sx={{
                  minWidth: '70px', // 70% of 100px
                  padding: '6px 12px', // approximately 70% of typical padding
                  fontSize: '0.7em', // 70% of the font size
                  lineHeight: 1, // to adjust the line height for smaller font size
                  borderWidth: '0.7px', // approximately 70% of typical 1px border
                  '& .MuiButton-label': {
                    fontSize: '0.7em', // reducing the font size of the button label
                  },
                }}
              >
                WORLDS
              </Button>

              {!isMobile && (
                <Typography
                  variant="h7"
                  sx={{
                    flex: 1,
                    textAlign: 'center',
                    paddingTop: isMobile ? '10px' : '0px',
                  }}
                >
                  {currentTitle}
                </Typography>
              )}

              <Button
                variant="outlined"
                color="primary"
                disabled={isLoading}
                onClick={handleClickNextChapter}
                sx={{
                  minWidth: '70px', // 70% of 100px
                  padding: '6px 12px', // approximately 70% of typical padding
                  fontSize: '0.7em', // 70% of the font size
                  lineHeight: 1, // to adjust the line height for smaller font size
                  borderWidth: '0.7px', // approximately 70% of typical 1px border
                  '& .MuiButton-label': {
                    fontSize: '0.7em', // reducing the font size of the button label
                  },
                }}
              >
                {isLoading ? <span>&nbsp;</span> : 'NEXT CHAPTER'}
              </Button>
            </Box>

            {isMobile && (
              <Typography
                variant="h7"
                sx={{
                  paddingTop: '10px',
                  textAlign: 'center',
                  paddingBottom: '10px',
                }}
              >
                {currentTitle}
              </Typography>
            )}

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Are you sure?'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Completed chapters have been saved.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    navigate('/hub');
                  }}
                  color="primary"
                  autoFocus
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          <Box
            ref={chatContainerRef}
            className="chat-container"
            sx={{
              //display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              gap: '10px',
              width: '100%',
              minHeight: '60vh',
              overflowY: 'auto',
              border: '0px solid',
              padding: '0px',
              fontSize: '24px',
            }}
          >
            {uiChatLog.map((message, i) => {
              let label;
              if (message.role === 'user') {
                label = localStorage.getItem('cName') + ' : ';
              } else if (message.role === 'assistant') {
                label = 'Narrator : ';
              } else if (message.role === 'system') {
                label = '';
              } else if (message.role === 'npc') {
                label = localStorage.getItem('npcName') + ' : ';
              } else if (!message.role) {
                label = '';
              }
              const color = message.role === 'npc' ? 'pink' : 'white';

              return (
                <Box
                  key={i}
                  sx={{
                    display: 'flex',
                    justifyContent: !message.role ? 'center' : 'flex-start',
                    paddingLeft: message.role === 'user' ? '50px' : '0px',
                    marginBottom: '10px', // Add a bottom margin to create spacing
                  }}
                >
                  <Typography align="left">
                    <strong>
                      {label}
                      {label && ''}
                    </strong>
                    <span
                      style={{ color: color }}
                      dangerouslySetInnerHTML={{ __html: message.content }}
                    />
                  </Typography>
                </Box>
              );
            })}
            <div ref={chatEndRef} />
          </Box>
          <form onSubmit={handleSendMessage}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                position: 'sticky', // Here is the updated part
                bottom: 0, // And here
                paddingBottom: '10px',
              }}
            >
              <Box sx={{ flexGrow: 1, paddingRight: '10px' }}>
                <TextField
                  fullWidth
                  inputRef={inputRef}
                  placeholder={placeholderText}
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  disabled={isLoading}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Box sx={{ paddingRight: '10px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    sx={{
                      height: '100%',
                      width: '80px',
                    }}
                  >
                    {isLoading ? <CircularProgress size={20} /> : 'Send'}
                  </Button>
                </Box>
                {exitVisible && (
                  <Box>
                    <Button
                      variant="contained"
                      onClick={handleExitNPC}
                      disabled={isLoading}
                      sx={{
                        backgroundColor: isLoading ? 'gray' : 'pink',
                        color: 'black',
                        width: '80px',
                        '&:hover': {
                          backgroundColor: isLoading ? 'gray' : '#ff69b4',
                        },
                      }}
                    >
                      {isLoading ? <CircularProgress size={20} /> : 'Exit'}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}

export default StoryPage;
